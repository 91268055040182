@import './../../../../scss/theme-bootstrap';

.country-selection-popup {
  text-align: center;
  &__header {
    margin-top: 25px;
    text-transform: uppercase;
  }
  &__text {
    margin: 20px 0;
    p {
      margin: 0;
      display: inline;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
  }
  &__button {
    .button--black {
      // inline-flex messes this up
      display: inline-block;
      padding: 10px;
      font-size: 15px;
      @include breakpoint($medium-up) {
        font-size: 1rem;
      }
    }
  }
  &__link {
    text-transform: uppercase;
    margin-top: 20px;
    a {
      color: $color-pink-accent;
      text-decoration: none;
    }
  }
}

.country-selection-popup-cbox {
  #cboxContent,
  #cboxLoadedContent {
    background: $color-pink-2;
  }
  #cboxClose:before {
    @include breakpoint($medium-up) {
      font-size: 40px;
    }
  }
}
